import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';
import Me from '../Me';

const sources = ['images/kitsune-chase.png', 'images/interview.png', 'images/jungle.png', 'images/resource-wall.png'];
const texts = ['Kitsune-Chase: A 2D Multiplayer Web Game with Phaser.JS', 'Interview Scheduler With React','Jungle With Ruby On Rails', 'Resource Wall - An app to allow users to store resources (à la Pinterest)' ];
const labels = ['Project 1','Project 2','Project 3','Project 4'];
const paths = ['https://github.com/jameshagans/Kitsune-Chase','https://github.com/jameshagans/Interview-Scheduler', 'https://github.com/jameshagans/Jungle', 'https://github.com/jameshagans/resourcewall'];

function Home() {
  return (
    <>
      <HeroSection />
      <Me title='About Me' subtitle='A Full Stack Developer and Technical Support Team Lead from Belfast, Ireland. Currently based in Vancouver BC, Canada'
        statement={['As a certified full stack developer, an experienced technical support professional and leader, I bring a strong foundation in technical troubleshooting, problem solving and leadership to the tech industry. Originally from Ireland, I moved to Canada in 2018 and am now a permanent resident. I made a move into Tech in 2019 where I was able to lead a Technical Support Team and continue my web development studies. I graduated from the Lighthouse labs web development course in May 2023.', 'I have a passion for web development and enjoy building my own projects with HTML, CSS Javascript, JQuery, React, Express, PostgreSQL and Ruby. The fully responsive website you are currently viewing was coded by me from scratch using React 🧑‍💻 I am confident that my experience in web development,  technical support and leadership will be an asset in the technical industry, and I am excited to take on new challenges.']} />

      <Cards title='Projects' subtitle='Here are some of the projects I have completed so far' sources={sources} texts={texts} labels={labels} paths={paths}/>

    </>
  );
}

export default Home;


import React from 'react';
import CardItem from './CardItem';
import './Cards.css'

function Cards(props) {
  return (
    <div className='cards'>
      <h1>{props.title}</h1>
      <p>{props.subtitle}</p>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem 
            src={props.sources[0]}
            text={props.texts[0]}
            label={props.labels[0]}
            path={props.paths[0]}
            />
            <CardItem 
            src={props.sources[1]}
            text={props.texts[1]}
            label={props.labels[1]}
            path={props.paths[1]}
            />

          </ul>
          <ul className='cards__items'>
            <CardItem 
            src={props.sources[2]}
            text={props.texts[2]}
            label={props.labels[2]}
            path={props.paths[2]}
            />
            <CardItem 
           src={props.sources[3]}
           text={props.texts[3]}
           label={props.labels[3]}
           path={props.paths[3]}
           />
         
        
          </ul>

        </div>
      </div>
    </div>
  );
}

export default Cards;

import React from "react";
import '../../App.css';
import CardItem from "../CardItem";

export default function Blog() {
  return(
    <>
     <h1 className='blog'>Welcome to my Blog</h1>
     <div className="cards">
     <h1>Blog Posts</h1>
  
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem 
            src='images/websockets.jpg'
            text='Real-time Apps and WebSockets By James Hagans'
            label='Medium Blog'
            path='https://medium.com/@jamesy.h95/exploring-options-for-building-real-time-apps-b9678afd0391'
            />
          </ul>
        </div>
      </div>
    </div>
     
    </>
  )
}